import React from "react"

export default () => {
    
    return (
    <div className={"impressum"}>
        <p className={"heading"}>Verantwortlich f&uuml;r den Inhalt der Seiten: </p> 
        <p>Prissilya Junewin
        <br/>USt.-IdNr.: DE327270342
        </p>
        <p className={"heading"}>
            Verantwortlich
            f&uuml;r die Gestaltung der
            Seiten:
        </p>
        <p>
            Patrick Pschorn
        </p>
        
        <p className={"heading"}>
            Allgemeine Gesch&auml;ftsbedingungen (AGB)
        </p>
        <p style={{textDecoration: "underline"}} >
             1 Geltung der Gesch&auml;ftsbedingungen 
        </p>
        <p>
            1.1 Die Produktion von Bildern und die Erteilung von Bildlizenzen erfolgt ausschlie&szlig;lich auf der Grundlage nachstehender Gesch&auml;ftsbedingungen. Diese Bedingungen gelten auch f&uuml;r alle k&uuml;nftigen Produktions- und Lizenzvertr&auml;ge, sofern nicht ausdr&uuml;cklich abweichende Regelungen vereinbart werden.
        </p>
        <p>
            1.2 Gesch&auml;ftsbedingungen des Auftraggebers, die von den nachstehenden Bedingungen abweichen, werden nicht anerkannt. Solche abweichenden Gesch&auml;ftsbedingungen werden auch dann nicht Vertragsinhalt, wenn der Fotograf ihnen nicht ausdr&uuml;cklich widerspricht. 
        </p>
        <p style={{textDecoration: "underline"}} >
            2 Produktionsauftr&auml;ge
        </p>
        <p>
            2.1 Kostenvoranschl&auml;ge des Fotografen sind unverbindlich. Kostenerh&ouml;hungen braucht der Fotograf nur anzuzeigen, wenn eine &Uuml;berschreitung der urspr&uuml;nglich veranschlagten Gesamtkosten um mehr als 15% zu erwarten ist.
        </p>
        <p>
            2.2 Bei Personenaufnahmen und bei Aufnahmen von Objekten, an denen fremde Urheberrechte, Eigentumsrechte oder sonstige Rechte Dritter bestehen, ist der Auftraggeber verpflichtet, die f&uuml;r die Anfertigung und Nutzung der Bilder erforderliche Zustimmung der abgebildeten Personen und der Rechtsinhaber einzuholen. Der Auftraggeber hat den Fotografen von Ersatzanspr&uuml;chen Dritter freizustellen, die aus der Verletzung dieser Pflicht resultieren. Die Freistellungsverpflichtung entf&auml;llt, sofern der Auftraggeber nachweist, dass ihn kein Verschulden trifft. Die vorstehende Regelung gilt auch dann, wenn der Fotograf die aufzunehmenden Personen oder Objekte selbst ausw&auml;hlt, sofern er den Auftraggeber so rechtzeitig &uuml;ber die getroffene Auswahl informiert, dass dieser die notwendigen Zustimmungserkl&auml;run-gen einholen oder andere geeignete Personen bzw. Objekte f&uuml;r die Aufnahmearbeiten ausw&auml;hlen und zur Verf&uuml;gung stellen kann. 
        </p>
        <p>
            2.3 Muss bei der Auftragsabwicklung die Leistung eines Dritten in Anspruch genommen oder ein sonstiger Vertrag mit Dritten abgeschlossen werden, ist der Fotograf bevollm&auml;chtigt, die entsprechenden Verpflichtungen im Namen und f&uuml;r Rechnung des Auftraggebers einzugehen. 
        </p>
        <p>
            2.4 Der Fotograf w&auml;hlt die Bilder aus, die er dem Auftraggeber bei Abschluss der Produktion zur Abnahme vorlegt. Nutzungsrechte werden unter der Voraussetzung vollst&auml;ndiger Zahlung (Ziffer 3.4) nur an den Bildern einger&auml;umt, die der Auftraggeber als vertragsgem&auml;&szlig; abnimmt. 
        </p>
        <p>
            2.5 Der Auftraggeber ist verpflichtet, die ihm nach Abschluss der Aufnahmearbeiten vorgelegten Bilder innerhalb einer angemessenen Frist zu untersuchen und eventuelle M&auml;ngel gegen&uuml;ber dem Fotografen zu r&uuml;gen. Die R&uuml;ge von offensichtlichen M&auml;ngeln muss schriftlich innerhalb von zwei Wochen nach Ablieferung der Bilder, die R&uuml;ge nicht offensichtlicher M&auml;ngel innerhalb einer Frist von zwei Wochen nach dem Erkennen des Mangels erfolgen. Zur Wahrung der R&uuml;gefrist gen&uuml;gt die rechtzeitige Absendung der R&uuml;ge. Bei Verletzung der Untersuchungs- und R&uuml;gepflicht gelten die Bilder in Ansehung des betreffenden Mangels als genehmigt. 
        </p>
        <p style={{textDecoration: "underline"}} >
            3 Produktionshonorar und Nebenkosten 
        </p>
        <p>
            3.1 Wird die f&uuml;r die Aufnahmearbeiten vorgesehene Zeit aus Gr&uuml;nden, die der Fotograf nicht zu vertreten hat, wesentlich &uuml;berschritten, ist ein vereinbartes Pauschalhonorar entsprechend zu erh&ouml;hen. Ist ein Zeithonorar vereinbart, erh&auml;lt der Fotograf auch f&uuml;r die Zeit, um die sich die Aufnahmearbeiten verl&auml;ngern, den vereinbarten Stunden- oder Tagessatz. 
        </p>
        <p>
            3.2 Der Auftraggeber hat zus&auml;tzlich zu dem geschuldeten Honorar die Nebenkosten zu erstatten, die dem Fotografen im Zusammenhang mit der Auftragsdurchf&uuml;hrung entstehen (z.B. f&uuml;r Filmmaterial, digitale Bildbearbeitung, Fotomodelle, Reisen). 
        </p>
        <p>
            3.3 Das Produktionshonorar ist bei Ablieferung der Bilder f&auml;llig. Wird eine Bildproduktion in Teilen abgeliefert, ist das entsprechende Teilhonorar jeweils bei Ablieferung eines Teiles f&auml;llig. Erstreckt sich die Ausf&uuml;hrung eines Auftrags &uuml;ber einen l&auml;ngeren Zeitraum, kann der Fotograf Abschlagszahlungen entsprechend dem erbrachten Arbeitsaufwand verlangen. 
        </p>
        <p>
            3.4 Bei einer Absage des Auftrags von Seiten des Auftraggebers bis zwei Tage vor der Fotoproduktion werden 50% des gesamten Produktionshonorars inklusive Nebenkosten berechnet. Bei einer Absage des Auftrags von Seiten des Auftraggebers innerhalb von 24 Stunden werden 100% des gesamten Produktionshonorars inklusive Nebenkosten berechnet.
        </p>
        <p>
            3.5 Die urheberrechtlichen Nutzungsrechte erwirbt der Auftraggeber erst mit der vollst&auml;ndigen Bezahlung des Honorars und der Erstattung s&auml;mtlicher Nebenkosten. 
        </p>
        <p style={{textDecoration: "underline"}} >
            4 Anforderung von Archivbildern 
        </p>
        <p>
            4.1 Bilder, die der Auftraggeber aus dem Archiv des Fotografen anfordert, werden zur Sichtung und Auswahl f&uuml;r die Dauer eines Monats ab Datum des Lieferscheins zur Verf&uuml;gung gestellt. Kommt innerhalb der Auswahlfrist kein Lizenzvertrag zustande, sind analoge Bilder und vom Fotografen zur Verf&uuml;gung gestellte Bilddatentr&auml;ger bis zum Ablauf der Frist zur&uuml;ckzugeben sowie s&auml;mtliche Bilddaten, die der Auftraggeber auf eigenen Datentr&auml;gern gespeichert hat, zu l&ouml;schen. 
        </p>
        <p>
            4.2 Mit der &Uuml;berlassung der Bilder zur Sichtung und Auswahl werden keine Nutzungsrechte &uuml;bertragen. Jede Nutzung bedarf einer vorherigen schriftlichen Freigabeerkl&auml;rung des Fotografen. 
        </p>
        <p>
            4.3 Die Verwendung der Bilder als Arbeitsvorlagen f&uuml;r Skizzen oder zu Layoutzwecken, ebenso die Pr&auml;sentation bei Kunden, stellt bereits eine kostenpflichtige Nutzung dar. Werden Diarahmen oder Folien ge&ouml;ffnet, ist der Fotograf - vorbehaltlich eines weitergehenden Zahlungsanspruchs - zur Berechnung eines Layouthonorars berechtigt, auch wenn es zu einer Nutzung der Bilder nicht gekommen ist. 
        </p>
        <p>
            4.4 F&uuml;r die Zusammenstellung der Bildauswahl kann der Fotograf eine Bearbeitungsgeb&uuml;hr berechnen, die sich nach Art und Umfang des entstandenen Aufwandes bemisst und mindestens 30&euro; betr&auml;gt. Versandkosten (Verpackung, Porto) einschlie&szlig;lich der Kosten f&uuml;r besondere Versandarten (Taxi, Luftfracht, Eilboten) hat der Auftraggeber zus&auml;tzlich zu erstatten. 
        </p>
        <p>
            4.5 Wird die in 4.1 geregelte oder die im Lizenzvertrag vereinbarte R&uuml;ckgabefrist f&uuml;r analoges Bildmaterial &uuml;berschritten, ist bis zum Eingang der Bilder beim Fotografen neben den sonstigen Kosten und Honoraren eine Blockierungsgeb&uuml;hr zu zahlen. Die Blockierungsgeb&uuml;hr betr&auml;gt 1,50&euro; pro Tag und Bild, wobei f&uuml;r das einzelne Bild ungeachtet der jeweiligen Blockierungsdauer h&ouml;chstens der Betrag gefordert werden kann, der in Ziffer 7.5 (Satz 2) der Gesch&auml;ftsbedingungen als Schadenspauschale f&uuml;r den Verlust des Bildes vorgesehen ist. Dem Auftraggeber bleibt der Nachweis vorbehalten, dass dem Fotografen durch die versp&auml;tete R&uuml;ckgabe der Bilder kein Schaden entstanden oder der entstandene Schaden wesentlich niedriger ist als die Blockierungsgeb&uuml;hr. 
        </p>
        <p style={{textDecoration: "underline"}} >
            5 Nutzungsrechte 
        </p>
        <p>
            5.1 Der Auftraggeber erwirbt an den Bildern nur Nutzungsrechte in dem vertraglich festgelegten Umfang. Eigentumsrechte werden nicht &uuml;bertragen. Ungeachtet des Umfangs der im Einzelfall einger&auml;umten Nutzungsrechte bleibt der Fotograf berechtigt, die Bilder im Rahmen seiner Eigenwerbung zu verwenden. 
        </p>
        <p>
            5.2 Die Einr&auml;umung und &&uuml;uml;bertragung der vom Auftraggeber erworbenen Nutzungsrechte an Dritte, auch an andere Redaktionen eines Verlags, bedarf der schriftlichen Zustimmung des Fotografen. 
        </p>
        <p>
            5.3 Eine Nutzung der Bilder ist grunds&auml;tzlich nur in der Originalfassung zul&auml;ssig. Jede &Auml;nderung oder Umgestaltung (z.B. Montage, fototechnische Verfremdung, Colorierung) und jede Ver&auml;nderung bei der Bildwiedergabe (z.B. Ver&ouml;ffentlichung in Ausschnitten) bedarf der vorherigen Zustimmung des Fotografen. Hiervon ausgenommen ist lediglich die Beseitigung ungewollter Unsch&auml;rfen oder farblicher Schw&auml;chen mittels digitaler Retusche. 
        </p>
        <p>
            5.4 Bei jeder Bildver&ouml;ffentlichung ist der Fotograf als Urheber zu benennen. Die Benennung muss beim Bild erfolgen. 
        </p>
        <p style={{textDecoration: "underline"}} >
            6 Digitale Bildverarbeitung 
        </p>
        <p>
            6.1 Die Digitalisierung analoger Bilder und die Weitergabe von digitalen Bildern im Wege der Datenfern&uuml;bertragung oder auf Datentr&auml;gern ist nur zul&auml;ssig, soweit die Aus&uuml;bung der einger&auml;umten Nutzungsrechte diese Form der Vervielf&auml;ltigung und Verbreitung erfordert. 
        </p>
        <p>
            6.2 Bilddaten d&uuml;rfen nur f&uuml;r die eigenen Zwecke des Auftraggebers und nur f&uuml;r die Dauer des Nutzungsrechts digital archiviert werden. Die Speicherung der Bilddaten in Online-Datenbanken oder sonstigen digitalen Archiven, die Dritten zug&auml;nglich sind, bedarf einer gesonderten Vereinbarung zwischen dem Fotografen und dem Auftraggeber. 
        </p>
        <p>
            6.3 Bei der digitalen Erfassung der Bilder muss der Name des Fotografen mit den Bilddaten elektronisch verkn&uuml;pft werden. Der Auftraggeber hat au&szlig;erdem durch geeignete technische Vorkehrungen sicherzustellen, dass diese Verkn&uuml;pfung bei jeder Daten&uuml;bermittlung, bei der &Uuml;bertragung der Bilddaten auf andere Datentr&auml;ger, bei der Wiedergabe auf einem Bildschirm sowie bei jeder &ouml;ffentlichen Wiedergabe erhalten bleibt und der Fotograf jederzeit als Urheber der Bilder identifiziert werden kann. 
        </p>
        <p style={{textDecoration: "underline"}} >
            7 Haftung und Schadensersatz 
        </p>
        <p>
            7.1 Der Fotograf haftet nur f&uuml;r Sch&auml;den, die er selbst oder seine Erf&uuml;llungsgehilfen vors&auml;tzlich oder grob fahrl&auml;ssig herbeif&uuml;hren. Davon ausgenommen sind Sch&auml;den aus der Verletzung einer Vertragspflicht, die f&uuml;r die Erreichung des Vertragszwecks von wesentlicher Bedeutung ist (Kardinalpflicht), sowie Sch&auml;den aus der Verletzung des Lebens, des K&ouml;rpers oder der Gesundheit, f&uuml;r die der Fotograf auch bei leichter Fahrl&auml;ssigkeit haftet. 
        </p>
        <p>
            7.2 Der Fotograf &uuml;bernimmt keine Haftung f&uuml;r die Art der Nutzung seiner Bilder. Insbesondere haftet er nicht f&uuml;r die wettbewerbs- und markenrechtliche Zul&auml;ssigkeit der Nutzung. 
        </p>
        <p>
            7.3 Anspr&uuml;che des Auftraggebers, die sich aus einer Pflichtverletzung des Fotografen oder seiner Erf&uuml;llungsgehilfen ergeben, verj&auml;hren ein Jahr nach dem gesetzlichen Verj&auml;hrungsbeginn. Davon ausgenommen sind Schadensersatzanspr&uuml;che, die auf einer vors&auml;tzlichen oder grob fahrl&auml;ssigen Pflichtverletzung des Fotografen oder seiner Erf&uuml;llungsgehilfen beruhen, und Schadensersatzanspr&uuml;che wegen Verletzung des Lebens, des K&ouml;rpers oder der Gesundheit, auch soweit sie auf einer leicht fahrl&auml;ssigen Pflichtverletzung des Fotografen oder seiner Erf&uuml;llungsgehilfen beruhen; f&uuml;r diese Schadensersatzanspr&uuml;che gelten die gesetzlichen Verj&auml;hrungsfristen. 
        </p>
        <p>
            7.4 Die Zusendung und R&uuml;cksendung von Bildern erfolgt auf Gefahr und f&uuml;r Rechnung des Auftraggebers. 
        </p>
        <p>
            7.5 Gehen analoge Bilder im Risikobereich des Auftraggebers verloren oder werden solche Bilder in einem Zustand zur&uuml;ckgegeben, der eine weitere Verwendung nach den &uuml;blichen Gepflogenheiten ausschlie&szlig;t, hat der Auftraggeber Schadensersatz zu leisten. Der Fotograf ist in diesem Fall berechtigt, mindestens Schadensersatz in H&ouml;he von 1.000&euro; f&uuml;r jedes Original und von 200&euro; f&uuml;r jedes Duplikat zu verlangen, sofern nicht der Auftraggeber nachweist, dass ein Schaden &uuml;berhaupt nicht entstanden oder wesentlich niedriger ist als die geforderte Schadenspauschale. Die Geltendmachung eines h&ouml;heren Schadensersatzanspruchs bleibt dem Fotografen vorbehalten. 
        </p>
        <p>
            7.6 Bei unberechtigter Nutzung, Ver&auml;nderung, Umgestaltung oder Weitergabe eines Bildes ist der Fotograf berechtigt, eine Vertragsstrafe in H&ouml;he des f&uuml;nffachen vereinbarten oder, mangels Vereinbarung, des f&uuml;nffachen &uuml;blichen Nutzungshonorars zu fordern, mindestens jedoch 500&euro; pro Bild und Einzelfall. Die Geltendmachung eines weitergehenden Schadensersatzanspruchs bleibt hiervon unber&uuml;hrt. 
        </p>
        <p>
            7.7 Unterbleibt bei einer Bildver&ouml;ffentlichung die Benennung des Fotografen (Ziffer 5.4) oder wird der Name des Fotografen mit dem digitalen Bild nicht dauerhaft verkn&uuml;pft (Ziffer 6.3), hat der Auftraggeber eine Vertragsstrafe in H&ouml;he von 100% des vereinbarten oder, mangels Vereinbarung, des &uuml;blichen Nutzungshonorars zu zahlen, mindestens jedoch 200&euro; pro Bild und Einzelfall. Dem Fotografen bleibt auch insoweit die Geltendmachung eines weitergehenden Schadensersatzanspruchs vorbehalten. 
        </p>
        <p style={{textDecoration: "underline"}} >
            8 Statut und Gerichtsstand 
        </p>
        <p>
            8.1 Es gilt das Recht der Bundesrepublik Deutschland. 
        </p>
        <p>
            8.2 F&uuml;r den Fall, dass der Auftraggeber keinen allgemeinen Gerichtsstand in der Bundesrepublik Deutschland hat oder seinen Sitz oder gew&ouml;hnlichen Aufenthalt nach Vertragsabschluss ins Ausland verlegt, wird der Wohnsitz des Fotografen als Gerichtsstand vereinbart.
        </p>
        <p className={"heading"}>
            Haftungsausschluss
        </p>
        <p style={{textDecoration: "underline"}} >
            Haftung f&uuml;r Inhalte
        </p>
        <p>
            Als Diensteanbieter ist der Seitenbetreiber gem&auml;&szlig; &sect; 7 Abs.1 TMG f&uuml;r eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach &sect;&sect; 8 bis 10 TMG ist der Seitenbetreiber als Dienstanbieter jedoch nicht verpflichtet, &uuml;bermittelte oder gespeicherte fremde Informationen zu &uuml;berwachen oder nach Umst&auml;nden zu forschen, die auf eine rechtswidrige T&auml;tigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unber&uuml;hrt. Eine diesbez&uuml;gliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung m&ouml;glich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden diese Inhalte umgehend entfernt.
        </p>
        <p style={{textDecoration: "underline"}} >
            Haftung f&uuml;r Links
        </p>
        <p>
            Das Angebot des Seitenbetreibers enth&auml;lt Links zu externen Webseiten Dritter, auf deren Inhalte der Seitenbetreiber keinen Einfluss hat. Deshalb k&ouml;nnen f&uuml;r diese fremden Inhalte auch keine Gew&auml;hr &uuml;bernommen werden. F&uuml;r die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf m&ouml;gliche Rechtsverst&ouml;&szlig;e &uuml;berpr&uuml;ft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden derartige Links umgehend entfernt.
        </p>
        <p style={{textDecoration: "underline"}} >
            Urheberrecht
        </p>
        <p>
            Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielf&auml;ltigung, Bearbeitung, Verbreitung und jede Art der Verwertung au&szlig;erhalb der Grenzen des Urheberrechtes bed&uuml;rfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur f&uuml;r den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, wird um einen entsprechenden Hinweis gebeten. Bei Bekanntwerden von Rechtsverletzungen werden derartige Inhalte umgehend entfernt.
        </p>
        <p style={{textDecoration: "underline"}} >
            Datenschutz
        </p>
        <p>
            Die Nutzung dieser Webseite ist in der Regel ohne Angabe personenbezogener Daten m&ouml;glich. Soweit auf den Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder eMail-Adressen) erhoben werden, erfolgt dies, soweit m&ouml;glich, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdr&uuml;ckliche Zustimmung nicht an Dritte weitergegeben.
        </p>
        <p>
            Es wird darauf hingewiesen, dass die Daten&uuml;bertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitsl&uuml;cken aufweisen kann. Ein l&uuml;ckenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht m&ouml;glich.
        </p>
        <p>
            Der Nutzung von im Rahmen der Impressumspflicht ver&ouml;ffentlichten Kontaktdaten durch Dritte zur &uuml;bersendung von nicht ausdr&uuml;cklich angeforderter Werbung und Informationsmaterialien wird hiermit ausdr&uuml;cklich widersprochen. Die Betreiber der Seiten behalten sich ausdr&uuml;cklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor.
        </p>
        <p style={{fontStyle: "italic"}}>
            Quellenangaben: <a href="http://www.e-recht24.de/muster-datenschutzerklaerung.html" target="_blank" rel="noreferrer">eRecht24 Datenschutzerkl&auml;rung</a>
        </p>		

        <p className={"heading"}>Datenschutzerkl&auml;rung</p>

        <p>Verantwortliche Stelle im Sinne der Datenschutzgesetze, insbesondere der EU-Datenschutzgrundverordnung (DSGVO), ist:</p>
        <p>
            Prissilya Junewin
        <br/><a href="mailto:info@prissilyajunewin.de">info@prissilyajunewin.de</a>
        <br/>DE +49 157 51519819
        </p>
        <p style={{textDecoration: "underline"}} >Ihre Betroffenenrechte</p>
        <p>Unter den angegebenen Kontaktdaten des Datenschutzbeauftragten dieser Seite k&ouml;nnen Sie jederzeit folgende Rechte aus&uuml;ben:</p>
        <ul>
        <li><p> Auskunft &uuml;ber Ihre beim Seitenbetreiber gespeicherten Daten und deren Verarbeitung,</p></li>
        <li><p> Berichtigung unrichtiger personenbezogener Daten,</p></li>
        <li><p> L&ouml;schung Ihrer beim Seitenbetreiber gespeicherten Daten,</p></li>
        <li><p> Einschr&auml;nkung der Datenverarbeitung, sofern Ihre Daten aufgrund gesetzlicher Pflichten noch nicht gel&ouml;scht d&uuml;rfen,</p></li>
        <li><p> Widerspruch gegen die Verarbeitung Ihrer Daten beim Seitenbetreiber und</p></li>
        <li><p> Daten&uuml;bertragbarkeit, sofern Sie in die Datenverarbeitung eingewilligt haben oder einen Vertrag mit dem Seitenbetreiber abgeschlossen haben.</p></li>
        </ul>
        <p>Sofern Sie dem Seitenbetreiber eine Einwilligung erteilt haben, k&ouml;nnen Sie diese jederzeit mit Wirkung f&uuml;r die Zukunft widerrufen.</p>
        <p>Sie k&ouml;nnen sich jederzeit mit einer Beschwerde an die f&uuml;r Sie zust&auml;ndige Aufsichtsbeh&ouml;rde wenden. Ihre zust&auml;ndige Aufsichtsbeh&ouml;rde richtet sich nach dem Bundesland Ihres Wohnsitzes, Ihrer Arbeit oder der mutma&szlig;lichen Verletzung. Eine Liste der Aufsichtsbeh&ouml;rden (f&uuml;r den nicht&ouml;ffentlichen Bereich) mit Anschrift finden Sie unter: <a href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html" target="_blank" rel="noreferrer">https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html</a>.</p>

        <p style={{textDecoration: "underline"}} >Zwecke der Datenverarbeitung durch die verantwortliche Stelle und Dritte</p>
        <p>Der Seitenbetreiber verarbeitet Ihre personenbezogenen Daten nur zu den in dieser Datenschutzerkl&auml;rung genannten Zwecken. Eine &Uuml;bermittlung Ihrer pers&ouml;nlichen Daten an Dritte zu anderen als den genannten Zwecken findet nicht statt. Der Seitenbetreiber gibt Ihre pers&ouml;nlichen Daten nur an Dritte weiter, wenn:</p>
        <ul>
        <li><p> Sie Ihre ausdr&uuml;ckliche Einwilligung dazu erteilt haben,</p></li>
        <li><p> die Verarbeitung zur Abwicklung eines Vertrags mit Ihnen erforderlich ist,</p></li>
        <li><p> die Verarbeitung zur Erf&uuml;llung einer rechtlichen Verpflichtung erforderlich ist,</p></li>
        </ul>
        <p>die Verarbeitung zur Wahrung berechtigter Interessen erforderlich ist und kein Grund zur Annahme besteht, dass Sie ein &uuml;berwiegendes schutzw&uuml;rdiges Interesse an der Nichtweitergabe Ihrer Daten haben.</p>

        <p style={{textDecoration: "underline"}} >Erfassung allgemeiner Informationen beim Besuch dieser Website</p>
        <p>Wenn Sie auf diese Website zugreifen, werden automatisch mittels eines Cookies Informationen allgemeiner Natur erfasst. Diese Informationen (Server-Logfiles) beinhalten etwa die Art des Webbrowsers, das verwendete Betriebssystem, den Domainnamen Ihres Internet-Service-Providers und &auml;hnliches. Hierbei handelt es sich ausschlie&szlig;lich um Informationen, welche keine R&uuml;ckschl&uuml;sse auf Ihre Person zulassen.</p>
        <p>Diese Informationen sind technisch notwendig, um von Ihnen angeforderte Inhalte von Webseiten korrekt auszuliefern und fallen bei Nutzung des Internets zwingend an. Sie werden insbesondere zu folgenden Zwecken verarbeitet:</p>
        <ul>
        <li><p> Sicherstellung eines problemlosen Verbindungsaufbaus der Website,</p></li>
        <li><p> Sicherstellung einer reibungslosen Nutzung dieser Website,</p></li>
        <li><p> Auswertung der Systemsicherheit und -stabilit&auml;t sowie</p></li>
        <li><p> zu weiteren administrativen Zwecken.</p></li>
        </ul>
        <p>Die Verarbeitung Ihrer personenbezogenen Daten basiert auf dem berechtigten Interesse des Seitenbetreibers aus den vorgenannten Zwecken zur Datenerhebung. Der Seitenbetreiber verwendet Ihre Daten nicht, um R&uuml;ckschl&uuml;sse auf Ihre Person zu ziehen. Empf&auml;nger der Daten sind nur die verantwortliche Stelle und ggf. Auftragsverarbeiter.</p>
        <p>Anonyme Informationen dieser Art werden ggfs. statistisch ausgewertet, um Internetauftritt und die dahinterstehende Technik zu optimieren.</p>

        {/* <p style={{textDecoration: "underline"}} >Verwendung von Google Analytics</p>
        <p>Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Inc. (folgend: Google). Google Analytics verwendet sog. "Cookies", also Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Webseite durch Sie erm&ouml;glichen. Die durch das Cookie erzeugten Informationen &uuml;ber Ihre Benutzung dieser Webseite werden in der Regel an einen Server von Google in den USA &uuml;bertragen und dort gespeichert. Aufgrund der Aktivierung der IP-Anonymisierung auf diesen Webseiten, wird Ihre IP-Adresse von Google jedoch innerhalb von Mitgliedstaaten der Europ&auml;ischen Union oder in anderen Vertragsstaaten des Abkommens &uuml;ber den Europ&auml;ischen Wirtschaftsraum zuvor gek&uuml;rzt. Nur in Ausnahmef&auml;llen wird die volle IP-Adresse an einen Server von Google in den USA &uuml;bertragen und dort gek&uuml;rzt. Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung der Webseite auszuwerten, um Reports &uuml;ber die Webseitenaktivit&auml;ten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegen&uuml;ber dem Webseitenbetreiber zu erbringen. Die im Rahmen von Google Analytics von Ihrem Browser &uuml;bermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengef&uuml;hrt.</p>
        <p>Die Zwecke der Datenverarbeitung liegen in der Auswertung der Nutzung der Website und in der Zusammenstellung von Reports &uuml;ber Aktivit&auml;ten auf der Website. Auf Grundlage der Nutzung der Website und des Internets sollen dann weitere verbundene Dienstleistungen erbracht werden. Die Verarbeitung beruht auf dem berechtigten Interesse des Webseitenbetreibers.</p>
        <p>Sie k&ouml;nnen die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; es wird jedoch darauf hingewiesen, dass Sie in diesem Fall gegebenenfalls nicht s&auml;mtliche Funktionen dieser Website vollumf&auml;nglich werden nutzen k&ouml;nnen. Sie k&ouml;nnen dar&uuml;ber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Webseite bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem sie das unter dem folgenden Link verf&uuml;gbare Browser-Plugin herunterladen und installieren: <a href="http://tools.google.com/dlpage/gaoptout?hl=de" target="_blank" rel="noopener">Browser Add On zur Deaktivierung von Google Analytics</a>.</p>
        <p>Zus&auml;tzlich oder als Alternative zum Browser-Add-On k&ouml;nnen Sie das Tracking durch Google Analytics auf den Seiten unterbinden, indem Sie <a title="Google Analytics Opt-Out-Cookie setzen" onClick="gaOptout();alert('Google Analytics wurde deaktiviert');" href="#">diesen Link anklicken</a>. Dabei wird ein Opt-Out-Cookie auf Ihrem Ger&auml;t installiert. Damit wird die Erfassung durch Google Analytics f&uuml;r diese Website und f&uuml;r diesen Browser zuk&uuml;nftig verhindert, so lange das Cookie in Ihrem Browser installiert bleibt.</p> */}

        <p style={{textDecoration: "underline"}} >Verwendung von Scriptbibliotheken (Google Webfonts)</p>
        <p>Um die Inhalte des Seitenbetreibers browser&uuml;bergreifend korrekt und grafisch ansprechend darzustellen, werden auf dieser Website Scriptbibliotheken und Schriftbibliotheken wie z. B. Google Webfonts (<a href="http://www.google.com/webfonts/" target="_blank" rel="noreferrer">https://www.google.com/webfonts/</a>) verwendet. Google Webfonts werden zur Vermeidung mehrfachen Ladens in den Cache Ihres Browsers &uuml;bertragen. Falls der Browser die Google Webfonts nicht unterst&uuml;tzt oder den Zugriff unterbindet, werden Inhalte in einer Standardschrift angezeigt.</p>
        <p>Der Aufruf von Scriptbibliotheken oder Schriftbibliotheken l&ouml;st automatisch eine Verbindung zum Betreiber der Bibliothek aus. Dabei ist es theoretisch m&ouml;glich - aktuell allerdings auch unklar ob und ggf. zu welchen Zwecken - dass Betreiber entsprechender Bibliotheken Daten erheben.</p>
        <p>Die Datenschutzrichtlinie des Bibliothekbetreibers Google finden Sie hier: <a href="https://www.google.com/policies/privacy/" target="_blank" rel="noreferrer">https://www.google.com/policies/privacy/</a></p>
        <p style={{textDecoration: "underline"}} >&Auml;nderung der Datenschutzbestimmungen</p>
        <p>Der Seitenbetreiber beh&auml;lt sich vor, diese Datenschutzerkl&auml;rung anzupassen, damit sie stets den aktuellen rechtlichen Anforderungen entspricht oder um &auml;nderungen der Leistungen des Seitenbetreibers in der Datenschutzerkl&auml;rung umzusetzen, z.B. bei der Einf&uuml;hrung neuer Services. F&uuml;r Ihren erneuten Besuch gilt dann die neue Datenschutzerkl&auml;rung.</p>
        <p style={{textDecoration: "underline"}} >Fragen an den Datenschutzbeauftragten</p>
        <p>Wenn Sie Fragen zum Datenschutz haben, schreiben Sie bitte eine E-Mail oder wenden Sie sich direkt an die f&uuml;r den Datenschutz verantwortliche Person: Prissilya Junewin.</p>

        <p style={{fontStyle: "italic"}}>Die Datenschutzerkl&auml;rung wurde mit dem <a href="https://www.activemind.de/datenschutz/datenschutzhinweis-generator/" target="_blank" rel="noreferrer" >Datenschutzerkl&auml;rungs-Generator der activeMind AG erstellt</a>.</p>	
    </div>
    );
    
}