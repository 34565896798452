import React from "react";
import { Component } from "react";

class InfoText extends Component {

    render () {
        const show_impressum = () => this.props.show_impressum();

        return (
            <table  className={"information"}>
                <tbody>
                <tr >
                    <td className={"bio"}>
                        <p className={"biocontent"}>
                        Prissilya Junewin is an Indonesian-born and German-raised Chinese photographer based in Berlin.
                        A mix of fashion, portrait and documentary photography influenced by her multi-cultural background, 
                        her work plays heavily on the notions of identity and belonging, collectiveness, self and perception. 
                        Clean lines, emotive lighting and a minimalist setup define her simple and honest approach. 
                        <br/>
                        <br/>
                        Recent publications and commissions include AnOther Magazine, i-D Magazine, Autre Magazine, Harper's Bazaar China, Vogue Germany, Elle Thailand, Wallpaper* Magazine, F.A.Z Quaterly, Zeit Magazin, Adidas, Closed, Nike, Nivea, Sony Music, Zalando, Jiyong Kim and William Fan.
                        <br/>
                        <br/>
                        Please get in touch for any requests and a commercial portfolio.
                        </p>
                        </td>

                        <td className={"contact landscape"}>
                            <p className={"biocontent"}>
                                <a href="mailto:info@prissilyajunewin.de">info@prissilyajunewin.de</a> 
                                <br/>
                                <a href="https://instagram.com/prissilyajunewin/" target="_blank" rel="noopener noreferrer">@prissilyajunewin</a>
                                <br/>
                                DE +49 157 51519819
                                <br/>
                                <br/>
                                Licensing by KINTZING
                                <br/>
                                <a href="mailto:chris@kintzing.com">chris@kintzing.com</a> 
                                <br/>
                                <br/>
                                <span onClick={show_impressum} className={"link"} title="Legal Notice">Legal Notice</span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                    <td className={"contact mobile"}>
                            <p className={"biocontent"}>
                                <a href="mailto:info@prissilyajunewin.de">info@prissilyajunewin.de</a> 
                                <br/>
                                <a href="https://instagram.com/prissilyajunewin/" target="_blank" rel="noopener noreferrer">@prissilyajunewin</a>
                                <br/>
                                DE +49 157 51519819
                                <br/>
                                <br/>
                                Licensing by KINTZING
                                <br/>
                                <a href="mailto:brogan@kintzing.com">brogan@kintzing.com</a> 
                                <br/>
                                <br/>
                                <span onClick={show_impressum}  title="Legal Notice">Legal Notice</span>
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
            
        );
    }
}


export default InfoText;
