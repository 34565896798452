import React from "react"
import {graphql, useStaticQuery } from "gatsby"
import JSONData_photo from "../data/descriptions_photographs.json";
import ImageCarousel from "../components/imageCarousel";

export default () => {
    const data = useStaticQuery(graphql`
    {
      Photographs: allFile(
        filter: {relativeDirectory: {eq: "Photographs"}}
        sort: {absolutePath: ASC}
      ) {
        edges {
          node {
            relativePath
            base
            childImageSharp {
              fluid(maxHeight: 712, quality: 100) {
                aspectRatio
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
    `);
    
    const { edges: photographs_ } = data.Photographs;
    
  return (
    <ImageCarousel images = {photographs_} pages = {JSONData_photo.pages} />
  )
}