import React from 'react';
import { Component } from "react";
import Img from "gatsby-image"
import "../components/layout.css";
import ImgWithOrient from './imageOrientation';
import InfoText from "../components/infoText";
import ImpressumText from './impressumText';

/**
 * Manage 
 * - image rotation, 
 * - positioning and 
 * - imageid (page-number)
 * - description
**/

class ImageCarousel extends Component {

    constructor(props) {
        super(props);
        // console.log("Image Carousel props", props); 
        var firstImgData = props.pages[0].page[0];
        var secondImgData = props.pages[1].page[0];
        var lastImgData = props.pages[props.pages.length-1].page[0]
        this.state = {
            page: [this.image(props.images[firstImgData.id], firstImgData)], // list of images on the page
            preload: [this.preload(props.images[lastImgData.id], lastImgData),
                      this.preload(props.images[secondImgData.id], secondImgData)], // load hidden images for preloading 
            current: 0, // first image is initially visible  
            pages: props.pages, // contains the json descriptions for each page
            showing: props.images, // references images loaded by Gatsby
            lastPage : props.pages.length-1,
            index: [],
            descriptionToPageMap: {},
            show_images: false,
            show_index: false,       
            show_information: false,
            show_impressum: false
        };
        this.show_impressum = this.show_impressum.bind(this)
        // console.log(this.state.pages);
    }

    componentDidMount() {
        this.show_index ()
      }
    
    // React modulo outputs negative numbers for negative input >> Needs positive output.
    mod(n, m) {
        return ((n % m) + m) % m;
      }

    reset() { 
        var imageData = this.state.pages[0].page[0];
        this.setState({ 
            show_images: true,
            show_index: false,
            show_information: false,
            show_impressum: false,
            page: [this.image(this.state.showing[0], imageData)],
            current: 0
        });
    }

    show_index () {
        // activate index view 
        this.setState({
            show_information: false,
            show_impressum: false,
            show_images: true,
            show_index: true,
            page: []
        });

        // load index if first time loading
        if (typeof this.state.index === 'undefined' | this.state.index.length === 0) {
            this.setState({
                preload: [], // required to prevent first image showing in index view -- bug: why is this image loaded to the view?
                index: this.index(this.state.pages),
            });
        }
    }

    showImageInOrder = (id, description) => {
        const currentPage = this.getCurrentIdFor(description);
        var imageData = this.state.pages[currentPage].page[0];
        this.setState({ 
            show_images: true,
            show_index: !this.state.show_index,
            page: [this.image(this.state.showing[id], imageData)],
            current: currentPage
        });
    }

    getCurrentIdFor = (description) => {
        if (this.state.descriptionToPageMap[description] != null) {
            return this.state.descriptionToPageMap[description];
        } else {
            for (let i = 0; i < this.state.pages.length; i++) {
                if (description === this.state.pages[i].description) {
                    var map = this.state.descriptionToPageMap;
                    map[description] = i;
                    this.setState({ 
                        descriptionToPageMap: map
                    });
                    return i;
                }
            }
            // console.log("Error: Did not find current Id for " +description);
            return 0; // Fallback
        }   
    }

    updateIndexPage = (id, description) => {
        const currentPage = this.getCurrentIdFor(description);
        var imageData = this.state.pages[currentPage].page[0];
        this.setState({ 
            page: [this.image(this.state.showing[id], imageData)]
        });
    };

    preloadImage = (id, pageId) => {
        var imageData = this.state.pages[pageId].page[0];
        this.setState({ 
            preload: this.state.page.concat([this.preload(this.state.showing[imageData.id], imageData)]),
          });
    };
    
    index = (pages) => {
        // make list of unique descriptions and map to page objects
        const descriptions = pages.map((page, i) => (page.description != null ?  page.description : ""));
        const uniqueNames = Array.from(new Set(descriptions));
        const updateIndexPage = this.updateIndexPage;
        const showImageInOrder = this.showImageInOrder;
        const preloadImage = this.preloadImage;
        return uniqueNames.map( function (name, pageId) {
            const p = pages.filter(page => page.description === name)[0];
            const id = p.page[0].id;
            preloadImage(id, pageId);
            return (<li className="indexEntry" key={id}>
                <button onMouseEnter={() => updateIndexPage(id, p.description)} onClick={() => showImageInOrder(id, p.description)}>
                    <span className="name" >{p.description != null ? ` ${p.description}` : ""}</span>
                    <span className="client">{p.client != null ? " " +p.client+ " ": " "}</span>
                    {/*<span className="name" style={{fontSize:1+'vw', marginRight: 1+ 'em'}}>/</span>*/}
                </button>
            </li>);
        });
    }

    preloadNextPage = () => {
        // preload image data for the image coming after the next regardless of the image being on the same page or the next
        var mId = this.mod(this.state.current+2, this.state.lastPage+1); 
        var preloadImageData = this.state.pages[mId];
        var preloadPage = preloadImageData.page.map((data) => this.preload(this.state.showing[data.id], data));
        this.setState({
            preload: preloadPage
        });
    }

    preloadPreviousPage = () => {
        // preload image data for the image coming before the previous regardless of the image being on the same page or the next
        var mId = this.mod(this.state.current-2, this.state.lastPage+1); 
        var preloadImageData = this.state.pages[mId];
        var preloadPage = preloadImageData.page.map((data) => this.preload(this.state.showing[data.id], data));
        this.setState({
            preload: preloadPage
        });
    }
      
    // preloading: load the k+1 th image into the preload area 
    next = () => {
        var imageData;
        // Load the first image on the next page or add an image to the current page if there are any more images on the same page
        if (this.state.page.length < this.state.pages[this.state.current].page.length) {
            // add image to the same page
            imageData = this.state.pages[this.state.current].page[this.state.page.length];
            this.setState({ 
                page: this.state.page.concat([this.image(this.state.showing[imageData.id], imageData)]),
              });
            
            
        } else {
            // next page
            var mId = this.mod(this.state.current+1, this.state.lastPage+1);  
            imageData = this.state.pages[mId].page[0];  
            this.setState({ 
                page: [this.image(this.state.showing[imageData.id], imageData)],
                current: mId
            });

            this.preloadNextPage();
        }
    }
    
    // preloading: load the j-1 th image into the preloading area
    previous = () => {
        // Load all images on the previous page or remove the latest image if there are more than 1 images on the page

        if (this.state.page.length > 1) {
            // remove latest image from the current page
            this.setState({ 
                page: this.state.page.slice(0, -1)
              });

        } else {
            // previous page
            var mId = this.mod(this.state.current-1, this.state.lastPage+1); 

            // - load the last two images first
            var first = this.state.pages[mId].page.slice(-2);
            this.setState({
                page: first.map((imageData) => this.image(this.state.showing[imageData.id], imageData)),
            });
            var that = this;
            setTimeout(function(){
            // - add the rest of the images
            var second = that.state.pages[mId].page.slice(0,-2);
                that.setState({
                    page: second.map((imageData) => that.image(that.state.showing[imageData.id], imageData)).concat(that.state.page),
                    current: mId
                });
            }, 20);

            this.preloadPreviousPage();
        }
    }
     
    image = (data, imageData) => {
        return (
                <ImgWithOrient  
                    key={imageData.id}
                    spec={imageData.position}
                    aspectRatio={data.node.childImageSharp.fluid.aspectRatio}
                    mystyle={imageData.style}
                    >
                    <Img
                        key={imageData.id+'img'}
                        fluid={data.node.childImageSharp.fluid}
                        alt={" "}
                        fadeIn={false}
                        loading="eager"
                        style={imageData.style}
                    />
                </ImgWithOrient>
           );
    }

    preload = (data, imageData) => {
        return (
                <ImgWithOrient  
                    key={imageData.id}
                    spec={imageData.position}
                    aspectRatio={data.node.childImageSharp.fluid.aspectRatio}
                    >
                    <Img
                        key={imageData.id+'img'}
                        fluid={data.node.childImageSharp.fluid}
                        alt={" "}
                        fadeIn={false}
                        style={{visibility: 'hidden'}}
                    />
                </ImgWithOrient>
           );
    }

    show_information = () => {
        // console.log("show info "+this.state.show_information );
        this.setState({
            show_images: false,
            show_index: false,
            show_impressum: false,
            show_information: true
        });
    }

    show_impressum = () => {
        // console.log("show impressum "+this.state.show_impressum );
        this.setState({
            show_images: false,
            show_index: false,
            show_information: false,
            show_impressum: true
        });
    }

    render() 
        { 
        // Get the description of the page
        var description = this.state.pages[this.state.current].description;
        var client = this.state.pages[this.state.current].client;

        // Navigation
        const next = () => this.next();
        const prev = () => this.previous();
        const reset = () => this.reset();
        const show_index = () => this.show_index();
        const show_information = () => this.show_information();
        //const show_impressum = () => this.show_impressum();t

        return (
            <div className="scrollplane">
            <div className="container">
                {!this.state.show_information && !this.state.show_impressum &&
                <div className={"navigationLayer"}>
                    {!this.state.show_index && <div className="column navigateLeft" onClick={prev} onKeyDown={prev}></div>}
                    {!this.state.show_index && <div className="column navigateRight" onClick={next} onKeyUp={next}></div>}
                </div>      
                }
                <header>
                    <table>
                        <tbody>
                        <tr>
                            <td className={"title link"} onClick={show_index}>
                                <span>Prissilya Junewin</span>
                                <br />
                                <span>张满玉</span> 
                            </td>
                            <td className={"menu"}>
                                    <span className="link" onClick={show_information}>Info</span>
                                    <br />
                                    <span className="link" onClick={show_index}>Index</span>
                                    <br />
                                    <span  className="link"><a href="mailto:info@prissilyajunewin.de">Mail</a></span>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </header>
                <main>
                    {this.state.show_index && <div className="indexContainer"><ul>{this.state.index}</ul></div>}
                    {this.state.show_information && <InfoText show_impressum={this.show_impressum}  /> }
                    {this.state.show_impressum && <ImpressumText /> }
                    {this.state.show_images && this.state.page}
                    {this.state.show_images && this.state.preload}
                </main>
                <footer>
                    <table>
                        <tbody>
                        {this.state.show_images && !this.state.show_index &&
                            <tr>
                                <td className={"description desktop"}>
                                        <p>
                                            <span className="name">{description != null ? ` ${description}` : ""}</span>
                                            <span className="client">{client != null ? " " +client : ""}</span>
                                        </p>
                                </td>
                                <td className={"description mobile"}>
                                    <p>
                                        <span className="name">{description != null ? ` ${description}` : ""}</span>
                                        <span className="client">{client != null ? " " +client : ""}</span>
                                    </p>
                                </td>
                            </tr>
                            }
                        </tbody>
                    </table>
                </footer>    
            </div>
            </div>

        )  
    }
}


export default ImageCarousel;
