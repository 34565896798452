import React from "react";

// we only care about `aspectRatio`, the rest will be passed directly to `Img`
const ImgWithOrient = ({ aspectRatio, mystyle, ...props }) => {
  let orientation;
  if (aspectRatio >= 1.05) orientation = "bild horizontal";
  if (aspectRatio <= 0.95) orientation = "bild vertikal";
  if (aspectRatio > 0.95 && aspectRatio < 1.05) orientation = "bild square"; // TODO dimensions for square images
  if (typeof mystyle !== 'undefined') {
    orientation = "bild custom";
  }
  return <div className={`${props.spec} ${orientation}`} style={{mystyle}} {...props} />;
  
};

export default ImgWithOrient;